import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";

import { Avatar, Box, Button, CardActions, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import PeopleIcon from '@mui/icons-material/People';

import { PeopleStat } from "../../models/Stats";
import { Period } from "../../models/Period";
import { getStartDate } from "../../functions/dateUtils";
import { ccyFormat } from "../../functions/formaters";

interface IProps {
    title: string;
    stats: PeopleStat[];
}

const EmployeeCard = ({stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();
    const [period, setPeriod] = useState<Period>("month");
    const startOfReport = getStartDate(period);

    const employees = stats.filter(s => new Date(s.date) >= startOfReport);
    const result = _.chain(employees)
        .groupBy(e => e.id)
        .map((res, e) => { return {id: e, deliveries: res.reduce((s, r) => r.deliveryCount + s , 0), ca: res.reduce((s, r) => r.associatedCa + s, 0)}})
        .value();
    //result.(r => r.reduce((s, e) => ))
    const totalDeliveries = result.reduce((s, e) => e.deliveries + s, 0);

    const handlePeriodChange = (
        event: React.MouseEvent<HTMLElement>,
        newPeriod: string,
    ) => {
        if(newPeriod != null) {
            setPeriod(newPeriod as Period);
        }
    };

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                {result.length == 0 ? <Typography>{intl.formatMessage({id: "nodata"})}</Typography> : <></>}
                <Table size="small" aria-label="simple table">
                    <TableBody>
                    {result.sort(c => -c.deliveries).map((row) => {
                        const people = employees.find(e => e.id == row.id);
                        return (
                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{minWidth: 40}}>
                                    <Button onClick={() => navigate(`/people/${row.id}`)} variant="text"><Typography>{people?.name}</Typography></Button>
                                </TableCell>
                                <TableCell sx={{minWidth: 35}} align="right">
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{row.deliveries} ({ccyFormat(row.ca)})</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                    )})}
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                <ToggleButtonGroup
                color="primary"
                value={period}
                exclusive
                size="small"
                onChange={handlePeriodChange}
                >
                    <ToggleButton value="week">{intl.formatMessage({id: "period.week"})}</ToggleButton>
                    <ToggleButton value="month">{intl.formatMessage({id: "period.month"})}</ToggleButton>
                </ToggleButtonGroup>
            </CardActions>
        </Card>
    );
}

export default EmployeeCard;
