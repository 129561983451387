import React, { Fragment, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as _ from 'lodash';

import { Button, CircularProgress, Divider, IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, TextField, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';

import Fab from "../../components/Fab";
import { useStorageState } from "../../functions/customHooks";
import { Activity } from "../../models/Activity";
import ActivityService from "../../services/ActivityService";
import PeopleService from "../../services/PeopleService";
import ContractService from "../../services/ContractService";
import VehicleService from "../../services/VehicleService";

import ActivityDialog, { PlanningWithDate } from "./ActivityDialog";
import { People } from "../../models/People";
import { Contract } from "../../models/Contract";
import { Vehicle } from "../../models/Vehicle";
import { AsyncIconButton } from "../../components/AsyncButton";
import { UserContext } from "../../functions/userContext";
import PlanningService from "../../services/PlanningService";
import { Planning } from "../../models/Planning";
import ActivityExportMenu from "./ActivityExportMenu";

const ActivityPage = () => {
    const intl = useIntl();
    const user = useContext(UserContext);
    const theme = useTheme();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [activities, setActivities] = useStorageState<Activity[]>('activities', []);
    const [people, setPeople] = useState<People[]>([]);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [plannings, setPlannings] = useState<Planning[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedActivity, setEditedActivity] = useState<Activity | undefined>(undefined);
    const [editedPlanning, setEditedPlanning] = useState<PlanningWithDate | undefined>(undefined);

    const [loading, setLoading] = useState(false);
    const [exportMenuTarget, setExportMenuTarget] = useState<Element | null>(null);
    const canAddActivity = !loading && (user?.subscriptionStatus == "Active" || user?.subscriptionStatus == "PaymentError" ||  activities.length == 0);
    const canGetPlannings = user?.hasPlanningAccess ?? false;

    useEffect(() => {
        PeopleService.get().then(setPeople);
        ContractService.get().then(setContracts);
        VehicleService.get().then(setVehicles);
    }, []);
    
    useEffect(() => {
        loadActivities();
    }, [selectedDate]);

    const handleActivityClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadActivities();
        }
    }

    const loadActivities = () => {
        setLoading(true);
        ActivityService.get(selectedDate).then((data) => {
            setActivities(data);
            setLoading(false);
        });

        if(canGetPlannings && selectedDate) {
            PlanningService.getForDate(selectedDate).then((data) => {
                setPlannings(data);
            })
        }
    }

    const deleteActivity = (activityId: string) => {
        return ActivityService.deleteActivity(activityId).then(() => loadActivities());
    }

    const handleOpenActivity = (activity: Activity | undefined) => {
        if(activity) {
            setEditedActivity(activity);
            setEditedPlanning(undefined);
        } else {
            setEditedActivity(undefined);
        }
        setOpenDialog(true);
    }

    const handleOpenPlanning = (planning: Planning | undefined) => {
        if(planning) {
            setEditedPlanning({
                ...planning,
                date: selectedDate
            });
            setEditedActivity(undefined);
        } else {
            setEditedPlanning(undefined);
        }
        setOpenDialog(true);
    }


    const handleSelectedDateChange = (date: Date | null) => {
        if (date !== null) {
            setSelectedDate(date)
        }
    }

    const handleChangeDate = (delta: number) => {
        const newDate = new Date();
        newDate.setDate(selectedDate.getDate() + delta);
        setSelectedDate(newDate);
    }
    
    const handleOpenExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportMenuTarget(event.currentTarget);
    };
      
    const handleCsvExport = (startDate?: Date, endDate?: Date) => {
        setExportMenuTarget(null)

        // create an hidden form in the document and submit the form to a new tab, allowing to post data
        if (startDate === undefined || endDate === undefined) return;

        const form = document.createElement("form");
        form.id = "exportcsvform";
        form.setAttribute("method", "post");
        form.setAttribute("action", "api/activity/export?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString());
        form.setAttribute("target", "downloadTab");
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "query");
        form.appendChild(hiddenField);
        document.body.appendChild(form);
        form.submit();
        document.getElementById("exportcsvform")?.remove();
    };

    const allContractIds = [...activities.map(a => a.contractId), ...plannings.map(p => p.contractId)]
    const distinctContractIds = allContractIds.filter((value, index, self) => self.indexOf(value) === index)

    const ContractGroup = (contractId: string) => {
        const groupActivities = activities.filter(a => a.contractId == contractId);
        const groupPlannings = plannings.filter(p => p.contractId == contractId);
        const contractName = contracts.find(c => c.id == contractId)?.name;
        return (<React.Fragment>
            <ListSubheader>{contractName}</ListSubheader>
            {groupActivities.map(act => ActivityItem(act))}
            {canGetPlannings && <Fragment>
                <Divider />
                {groupPlannings.map(p => PlanningItem(p))}
            </Fragment>}
        </React.Fragment>);
    }

    const ActivityItem = (activity: Activity) => {
        const peopleName = people.find(p => p.id == activity.peopleId)?.name ?? "N/A";
        const vehicle = vehicles.find(v => v.id == activity.vehicleId);

        return (<ListItem key={activity.id}>
            <ListItemButton onClick={() => handleOpenActivity(activity)}>
                <ListItemText primary={activity.tourCode} secondary={`${peopleName} - ${vehicle?.type} ${vehicle?.matriculation !== '' ? '(' + vehicle?.matriculation + ')' : ''}`}/>
            </ListItemButton>
            <ListItemSecondaryAction>
                <AsyncIconButton edge="end" ariaLabel="delete" onClick={() => deleteActivity(activity.id)}>
                    <DeleteIcon />
                </AsyncIconButton>
            </ListItemSecondaryAction>
        </ListItem>);
    }

    const PlanningItem = (planning: Planning) => {
        const peopleName = people.find(p => p.id === planning.peopleId)?.name;
        const vehicleName = vehicles.find(v => v.id === planning.vehicleId)?.matriculation;
        
        return (<ListItem key={planning.id} sx={{backgroundColor: theme.palette.grey[300]}}>
            <ListItemButton onClick={() => handleOpenPlanning(planning)}>
                <ListItemText primary={planning.tourCode} secondary={`${peopleName} - ${vehicleName}`}/>
            </ListItemButton>
        </ListItem>);
    }

    return (
        <Fragment>
            <Stack direction="row" flex={100}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} flex={100}>
                    <IconButton aria-label="previous" onClick={() => handleChangeDate(-1)}>
                        <KeyboardArrowLeftIcon fontSize="inherit" />
                    </IconButton>
                    <MobileDatePicker
                        label={intl.formatMessage({ id: 'activity.date' })}
                        inputFormat="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleSelectedDateChange}
                        renderInput={(params: any) => <TextField {...params} />}
                    />
                    <IconButton aria-label="next"  onClick={() => handleChangeDate(1)}>
                        <KeyboardArrowRightIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
                <Button size="small" variant="outlined" onClick={handleOpenExportMenu} startIcon={<FileDownloadIcon />}>
                    {intl.formatMessage({ id: 'activity.export' })}
                </Button>
            </Stack>
            <List
                sx={{ width: '100%'}}
                component="nav">
                {distinctContractIds.map(c => ContractGroup(c))}
            </List>
            <Fab sx={{ position: 'fixed', right: 16, bottom: 16 }} disabled={!canAddActivity} color="primary" aria-label="add" onClick={() => handleOpenActivity(undefined)}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <ActivityDialog 
                open={openDialog}
                handleClose={handleActivityClose}
                date={selectedDate}
                activity={editedActivity}
                planning={editedPlanning}
                availablePeople={people.filter(p => !p.deleted)}
                availableContracts={contracts.filter(p => !p.deleted)}
                availableVehicles={vehicles.filter(p => !p.deleted)}
                usedTourCodes={activities.map(a => a.tourCode)}
            />
            <ActivityExportMenu anchorEl={exportMenuTarget} handleCsvExport={handleCsvExport} handleClose={() => setExportMenuTarget(null)} />
        </Fragment>);
}


export default ActivityPage;