import download from "downloadjs";
import { Invoice, InvoiceData } from "../models/Invoice";

export class InvoiceService
{
    static async get(date: Date | undefined = undefined, contractId: string | undefined = undefined, peopleId: string | undefined = undefined ) {
        const dateParam = date !== undefined ? 'date=' + date?.toISOString() : '';
        const contractParam = contractId !== undefined ? 'contractId=' + contractId : '';
        const peopleParam = peopleId ? 'peopleId=' + peopleId : '';
        const params = [dateParam, contractParam, peopleParam].join("&");
        const response = await fetch(`/api/invoice?` +  params);
        return await response.json() as Invoice[];
    }
    
    static async getData(invoiceId: string) {
        const response = await fetch(`/api/invoice/` +  invoiceId);

        if(response && response.headers) {
            const filename = response?.headers?.get("content-disposition")?.split(';')[1].replace("filename=", "");
            const mimeType = response?.headers?.get("content-type") ?? "application/pdf";
            
            download(await response.blob(), filename, mimeType);
        }
    }

    static async addInvoice(data: InvoiceData, date: Date | undefined = undefined, contractId: string | undefined = undefined, peopleId: string | undefined = undefined) {
        const dateParam = date !== undefined ? 'date=' + date?.toISOString() : '';
        const contractParam = contractId ? 'contractId=' + contractId : '';
        const peopleParam = peopleId ? 'peopleId=' + peopleId : '';
        const params = [dateParam, contractParam, peopleParam].join("&");
        await fetch("/api/invoice?" +  params, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async deleteInvoice(invoiceId: string) {
        await fetch("/api/invoice/" + invoiceId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }
}

export default InvoiceService;