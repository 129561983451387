import { Activity } from "../models/Activity";
import { PackageCost, PriceLevel } from "../models/Contract";

export function computePriceLevels(count: number, priceLevels: PriceLevel[])
{
    const appliedPriceLevels = [...priceLevels].sort((l1, l2) => (l1.level ?? Number.MAX_SAFE_INTEGER) - (l2.level ?? Number.MAX_SAFE_INTEGER));
    return appliedPriceLevels.reduce((buf, level) => {
        if(buf.cnt <= 0) {
            return buf;
        }

        const appliedLevel = level.level ?? Number.MAX_SAFE_INTEGER;
        return {sum: buf.sum +  Math.min(buf.cnt, appliedLevel) * (level.amount ?? 0), cnt: buf.cnt - appliedLevel};
    }, {sum: 0, cnt: count}).sum;
}


    
export function computeActivitiesCA(activities: Activity[], packageCosts: PackageCost[])
{
    return activities.reduce((s, a) => {
        const activityGain = Object.keys(a.packages).reduce((sum, p) => {
            const pc = packageCosts.find(pc => pc.id == p) ?? 
            packageCosts.find(pc => pc.id == "standard");

            if (pc === undefined) {
                return sum;
            }

            const pa = a.packages[p];
            return sum + computePriceLevels(pa.clientDeliveries ?? 0, pc.clientDeliveryCosts ?? []) +
            computePriceLevels(pa.relayDeliveries ?? 0, pc.relayDeliveryCosts ?? []) +
            computePriceLevels(pa.clientPickups  ?? 0, pc.clientPickupCosts ?? []) +
            computePriceLevels(pa.relayPickups ?? 0, pc.relayPickupCosts ?? []);
        }, 0);

        return s + activityGain;
    },0);
}