import { ActivityStats } from "../models/Stats";


export class DashboardService
{
    static async getDashboardStats(contractId: string) {
        const response = await fetch("/api/dashboard/stats?contractId=" + contractId, {
            method: "GET"
        });
        return await response.json() as ActivityStats;
    }
}

export default DashboardService;