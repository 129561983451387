import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemButton,  ListItemText } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { stringAvatar } from "../../functions/avatar"
import { useStorageState } from "../../functions/customHooks";
import { People } from "../../models/People";
import PeopleService from "../../services/PeopleService";
import PeopleDialog from "./PeopleDialog";
import Fab from "../../components/Fab";
import { Contract } from "../../models/Contract";
import { ContractService } from "../../services/ContractService";

const PeoplePage = () => {
    const navigate = useNavigate();

    const [people, setPeople] = useStorageState<People[]>('people', []);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedPeople, setEditedPeople] = useState<People | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ContractService.get().then(setContracts);
        loadPeople();
    }, []);

    const handlePeopleClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadPeople();
        }
    }

    const loadPeople = () => {
        setLoading(true);
        PeopleService.get().then((data) => {
            setPeople(data);
            setLoading(false);
        });
    }

    const handleOpenDialog = (people: People | undefined) => {
        setEditedPeople(people);
        setOpenDialog(true);
    }

    const PeopleItem = (people: People) => {
        return (<ListItem
            key={people.id}
            secondaryAction={
                <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(people)}>
                    <EditIcon />
                </IconButton>} >
            <ListItemButton onClick={() => navigate("/people/" + people.id)}>
                <ListItemAvatar>
                    <Avatar {...stringAvatar(people.name)} />
                </ListItemAvatar>
                <ListItemText primary={people.name} />
            </ListItemButton>
        </ListItem>)
    }

    return (
        <Fragment>
            <List
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="nested-list-subheader">
                {people.map(PeopleItem)}
            </List>
            <Fab sx={{ position: 'fixed', right: 16, bottom: 16 }} disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog(undefined)}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <PeopleDialog open={openDialog} people={editedPeople} contracts={contracts} handleClose={handlePeopleClose} />
        </Fragment>);
}


export default PeoplePage;