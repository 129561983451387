import { useState } from "react";
import { useIntl } from "react-intl";
import * as _ from "lodash";

import { CardActions, CardContent, CardHeader, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    BarController,
    LineController,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';

import 'chartjs-adapter-date-fns';
import { Chart } from 'react-chartjs-2';
import { Period } from "../../models/Period";
import { DeliveryStat } from "../../models/Stats";
import { getStartDate } from "../../functions/dateUtils";
import { alpha, color } from "@mui/system";

ChartJS.register(
    TimeScale,
    LinearScale,
    BarController,
    LineController,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

interface IProps {
    title: string;
    stats: DeliveryStat[]
}

const DeliveriesChart = ({ stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const [period, setPeriod] = useState<Period>("day");

    const endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    const startDate = getStartDate("month");

    const daysScale = Array.from(Array(endDate.getDate()).keys()).map(d => new Date(endDate.getFullYear(), endDate.getMonth(), 1 + d));

    const deliveries = _.chain(stats)
        .filter(s => new Date(s.date) >= startDate)
        .orderBy(s => new Date(s.date))
        .value();
        
    const deliveriesData = daysScale.map(d => deliveries.find(del => new Date(del.date).getDate() === d.getDate())?.count ?? 0);
    const caData = daysScale.map(d => deliveries.find(del =>  new Date(del.date).getDate() === d.getDate())?.associatedCa ?? 0);
    
    const labels = daysScale.map(d => intl.formatDate(d, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }));

    const data = {
        datasets: [{
            type: 'bar' as const,
            label: 'Deliveries',
            data: deliveriesData,
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            yAxisID: 'y'
        }, {
            type: 'line' as const,
            label: 'CA',
            data: caData,
            borderColor: theme.palette.error.main,
            tension: 0.5,
            yAxisID: 'y1'
        }],
        labels: labels
    };
    
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                position: 'left' as 'left',
                grid: {
                    drawOnChartArea: false
                }
            },
            y1: {
                position: 'right' as 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        }
    }

    const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
        if(newPeriod != null) {
            setPeriod(newPeriod as Period);
        }
    };

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                <Chart type='bar' data={data} options={options}/>
            </CardContent>
            <CardActions>
                <ToggleButtonGroup
                color="primary"
                value={period}
                exclusive
                size="small"
                onChange={handlePeriodChange}
                >
                    <ToggleButton value="day">{intl.formatMessage({id: "range.day"})}</ToggleButton>
                    {/* <ToggleButton value="week">{intl.formatMessage({id: "range.week"})}</ToggleButton>
                    <ToggleButton value="month">{intl.formatMessage({id: "range.month"})}</ToggleButton> */}
                </ToggleButtonGroup>
            </CardActions>
        </Card>
    );
}

export default DeliveriesChart;
