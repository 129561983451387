import { Fragment, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { Avatar, Box, Card, CardContent, CardHeader, Link, Grid, IconButton, Paper, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Button, List, ListItem, ListItemText, Divider, Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EuroIcon from '@mui/icons-material/Euro';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { stringAvatar } from "../../functions/avatar"
import { ccyFormat, formatDate, formatDateOnly, formatHours } from "../../functions/formaters";
import PeopleService from "../../services/PeopleService";

import { PeopleStats } from "../../models/People";
import GaugeCard from "../../components/GaugeCard";
import { computeActivitiesCA } from "../../functions/compute";
import BonusDialog from "./BonusDialog";
import PeopleWorktimeChart from "./PeopleWorktimeChart";
import { Invoice } from "../../models/Invoice";
import InvoiceService from "../../services/InvoiceService";
import { AsyncIconButton } from "../../components/AsyncButton";
import PeopleInvoiceDialog from "./PeopleInvoiceDialog";
import { Contract } from "../../models/Contract";
import ContractService from "../../services/ContractService";


const deliveriesPerIncomeKind = (people: PeopleStats, contracts: Contract[]) => {

    const deliveryIncomeValues : { [kind: string] : number } = {}

    Object.keys(people.deliveryIncomes ?? {}).forEach(key => {
        deliveryIncomeValues[key] = 0
    });

    people.activities.forEach((a) => {
        Object.keys(a.packages).forEach((p) => {
            const pa = a.packages[p];
            const kindName = contracts.find(c => c.id === a.contractId)?.packageCosts.find(pc => pc.id === p)?.name ?? "Standard"
            
            const deliveryCount = (pa.clientDeliveries ?? 0) + 
                (pa.relayDeliveries ?? 0)

            if (kindName in deliveryIncomeValues) {
                deliveryIncomeValues[kindName] += deliveryCount;
            } else {
                deliveryIncomeValues["Standard"] += deliveryCount;
            }
            
        });
    });

    return deliveryIncomeValues
}

const PeopleBaseCard = (people: PeopleStats) => {
    const intl = useIntl();

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar {...stringAvatar(people.name)} />
                }
                title={people.name}
                subheader={`${intl.formatMessage({ id: 'people.detail.creationDate' })} ${formatDate(intl, people.creationDate)}`}
            />
            <CardContent>
                <List>
                    {people.activities.sort((a, b) => (new Date(b.date)).getDate() - (new Date(a.date)).getDate()).map(a => {
                        return (
                        <ListItem key={a.id} disablePadding>
                            <ListItemText primary={`${formatDateOnly(intl, a.date)} - ${formatHours(intl, a.startTime)} / ${formatHours(intl, a.endTime)}`} secondary={`${a.pauseDuration} ${intl.formatMessage({id: 'people.detail.pauseDuration'})}`} />
                        </ListItem>)
                    })}
                </List>
            </CardContent>
        </Card>
    );
}

const PeopleActivityCard = (people: PeopleStats, isInPast: boolean) => {
    const intl = useIntl();
    const activityCount = people.activities.length;

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    const daysRatio = isInPast ? 1 : currentDay / daysInMonth;
    const daysObjective = 20 * daysRatio;

    return (
        <GaugeCard title={intl.formatMessage({ id: 'people.detail.activity' })} objective={daysObjective} value={activityCount} label={intl.formatMessage({ id: 'day' })} succesRatio={0.8} errorRatio={0.2} />
    );
}

const PeopleChargeCard = (people: PeopleStats, contracts: Contract[], onAddBonus: VoidFunction, onDeleteBonus: (bonusId: string) => void) => {
    const intl = useIntl();
    const deliveryIncomeValues = deliveriesPerIncomeKind(people, contracts)

    const deliveryIncome = Object.keys(people.deliveryIncomes ?? {}).reduce((s, key) => {
        if (people.deliveryIncomes && key in deliveryIncomeValues && deliveryIncomeValues[key] > 0) {
            return s += deliveryIncomeValues[key] * people.deliveryIncomes[key];
        }

        return s;
    }, 0);

    return (
        <Card>
            <CardHeader
                title={intl.formatMessage({ id: 'people.detail.charge' })}
                subheader={intl.formatMessage({ id: 'people.detail.chargeDetail' })}
                avatar={<Avatar sx={{ backgroundColor: "red" }}><EuroIcon /></Avatar>} />
            <CardContent>
                <Typography variant="h5" component="div">
                    {`${ccyFormat(people.income + (people.income * 0.45) + deliveryIncome)}`}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {`${intl.formatMessage({ id: 'people.detail.baseIncome' })} : ${ccyFormat(people.income)}`}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {`${intl.formatMessage({ id: 'people.detail.afterBonus' })} : ${ccyFormat(people.income + (people.income * 0.45) + people.bonuses.reduce((s, p) => s + p.amount, 0)) }`}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {`${intl.formatMessage({ id: 'people.detail.subcontracting' })} : ${ccyFormat(deliveryIncome) }`}
                </Typography>
                <Divider sx={{m: 3}}>
                    {intl.formatMessage({id: 'people.detail.bonuses'})}                
                    <IconButton aria-label="delete" size="small" onClick={onAddBonus}>
                        <AddCircleIcon/>
                    </IconButton>
                </Divider>
                <List>
                    {people.bonuses.sort((a, b) => a.date.getDate() - b.date.getDate()).map(b => {
                        return (
                        <ListItem disablePadding 
                            secondaryAction={<IconButton edge="end" onClick={() => onDeleteBonus(b.id)}>
                                <DeleteIcon />
                            </IconButton>}>
                            <ListItemText primary={`${ccyFormat(b.amount)}`} secondary={formatDate(intl, b.date)} />
                        </ListItem>)
                    })}
                </List>
            </CardContent>
        </Card>
    );
}

const PeopleCACard = (people: PeopleStats, navigate: NavigateFunction) => {
    const intl = useIntl();

    const contractCAs = people.contractStats.map(cs => {
        const contractActivities = people.activities.filter(a => a.contractId == cs.id);
        const activitiesCa = computeActivitiesCA(contractActivities, cs.packageCosts);
        const forfaitCa = (cs.forfait ?? 0) * cs.ratio;

        return { id: cs.id, name: cs.name, ca: activitiesCa + forfaitCa };
    });

    const totalCA = contractCAs.reduce((s, ca) => {
        return s + ca.ca;
    }, 0);

    const ContractDetail = (contractStats: { id: string, name: string, ca: number }) => {
        return (
            <TableRow key={`people-table-${contractStats.id}`}>
                <TableCell>
                    <Button onClick={() => navigate(`/contract/${contractStats.id}`)} variant="text"><Typography>{contractStats.name}</Typography></Button>
                </TableCell>
                <TableCell align="right"><b>{ccyFormat(contractStats.ca)}</b></TableCell>
            </TableRow>);
    }

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: 'people.detail.ca' })} />
            <CardContent>
                <TableContainer>
                    <Table aria-label="people-table">
                        <TableHead>
                            <TableRow key="people-table-header">
                                <TableCell>{intl.formatMessage({ id: 'people.detail.contract' })}</TableCell>
                                <TableCell align="right">{intl.formatMessage({ id: 'people.detail.cashort' })}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contractCAs.map(cs => ContractDetail(cs))}
                            <TableRow key='people-table-total'>
                                <TableCell align="right">
                                    <Typography><b>{intl.formatMessage({ id: 'total' })}</b></Typography>
                                </TableCell>
                                <TableCell align="right"><b>{ccyFormat(totalCA)}</b></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}

const PeopleDetailPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [people, setPeople] = useState<PeopleStats>();
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);

    const { id } = useParams();


    useEffect(() => {
        ContractService.get().then(setContracts);
    }, []);

    useEffect(() => {
        reloadPeople()
    }, [selectedDate]);
    

    const loadInvoices = () => {
        InvoiceService.get(selectedDate, undefined, id).then(setInvoices);
    }

    const reloadPeople = () => {
        if (id) {
            PeopleService.getById(id, selectedDate).then(setPeople);
            loadInvoices()
        }
    }

    const handleSelectedDateChange = (date: Date | null) => {
        if (date !== null) {
            setSelectedDate(date)
        }
    }

    const handleChangeDate = (delta: number) => {
        const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + delta));
        setSelectedDate(newDate);
    }

    const [bonusOpen, setBonusOpen] = useState(false)

    const onAddBonus = () => {
        setBonusOpen(true)
    }
    
    const onCloseBonus = (result: boolean) => {
        setBonusOpen(false)
        if(result) {
            reloadPeople()
        }
    }
    
    const onDeleteBonus = (bonusId: string) => {
        PeopleService.deleteBonus(bonusId).then(reloadPeople)
    }

    const currentDate = new Date();
    const isInPast = selectedDate.getFullYear() !== currentDate.getFullYear() || selectedDate.getMonth() !== currentDate.getMonth();

    const handleDeleteInvoice = (id: string) => {
        return InvoiceService.deleteInvoice(id).then(loadInvoices);
    }

    const handleViewInvoice = (id: string) => {
        return InvoiceService.getData(id);
    }

    const handleInvoiceClose = (result: boolean) => {
        setInvoiceDialogOpen(false);
        if (result) {
            loadInvoices();
        }
    }
    
    const InvoiceRow = (invoice : Invoice) => {
        return <TableRow key={invoice.id}>
            <TableCell sx={{width: 20 }}>
                <AsyncIconButton
                    onClick={() => handleViewInvoice(invoice.id)}>
                    <VisibilityIcon/>
                </AsyncIconButton>
            </TableCell>
            <TableCell>{invoice.name}</TableCell>
            <TableCell align="right">{formatDate(intl, invoice.date)}</TableCell>
            <TableCell align="right">
                <AsyncIconButton
                    onClick={() => handleDeleteInvoice(invoice.id)}>
                    <DeleteIcon/>
                </AsyncIconButton>
            </TableCell>
        </TableRow>
    }
    
    const invoiceBaseLines = () => {
        if (people === undefined) {
            return []
        }

        const deliveryIncomeValues = deliveriesPerIncomeKind(people, contracts)

        const allLines = []

        Object.keys(people.deliveryIncomes ?? {}).forEach(key => {
            if (people.deliveryIncomes && key in deliveryIncomeValues && deliveryIncomeValues[key] > 0) {
                allLines.push({name: intl.formatMessage({ id: 'people.deliveryIncome'}) + ' - ' + key, quantity: Math.round(deliveryIncomeValues[key]), unitPrice: Math.round(100 * people.deliveryIncomes[key]) / 100, value: Math.round(100 * deliveryIncomeValues[key] * people.deliveryIncomes[key]) / 100})
            }
        }); 
        
        if (people.bonuses.length > 0) {
            const bonus = Math.round(100 * people.bonuses.reduce((s, b) => b.amount + s, 0)) / 100;
            allLines.push({name: intl.formatMessage({ id: "people.detail.bonuses"}), quantity: 1, unitPrice: bonus, value: bonus})
        }
        
        return allLines
    }

    return (
        <Fragment>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} flex={100}>
                <IconButton aria-label="previous" onClick={() => handleChangeDate(-1)}>
                    <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <MobileDatePicker
                    views={["month", "year"]}
                    label={intl.formatMessage({ id: 'reports.date' })}
                    inputFormat="MMMM yyyy"
                    value={selectedDate}
                    onChange={handleSelectedDateChange}
                    renderInput={(params: any) => <TextField {...params} />}
                />
                <IconButton aria-label="next" onClick={() => handleChangeDate(1)}>
                    <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            {people &&
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item
                                xl={3}
                                lg={6}
                                sm={6}
                                xs={12}>
                                {PeopleBaseCard(people)}
                            </Grid>
                            <Grid item
                                xl={3}
                                lg={6}
                                sm={6}
                                xs={12}>
                                {PeopleActivityCard(people, isInPast)}
                            </Grid>
                            <Grid item
                                xl={3}
                                lg={6}
                                sm={6}
                                xs={12}>
                                {PeopleChargeCard(people, contracts, onAddBonus, onDeleteBonus)}
                            </Grid>
                            <Grid item
                                xl={3}
                                lg={6}
                                sm={6}
                                xs={12}>
                                {PeopleCACard(people, navigate)}
                            </Grid>
                            <Grid item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}>
                                <PeopleWorktimeChart stats={people.worktime} title={intl.formatMessage({ id: 'people.detail.worktime' })} />
                            </Grid>
                        </Grid>
                        {(people.status === "Contractor" || invoices.length > 0) && (
                            <Fragment>
                                <Typography variant="subtitle1" gutterBottom component="div" sx={{mt: 4, mb: 2}}>
                                    {intl.formatMessage({id: 'contract.detail.invoices'})}
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table aria-label="invoices-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{maxWidth: 5 }}/>
                                                <TableCell>{intl.formatMessage({id: 'contract.detail.invoices.name'})}</TableCell>
                                                <TableCell align="right">{intl.formatMessage({id: 'contract.detail.invoices.date'})}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton onClick={() => setInvoiceDialogOpen(true)}>
                                                        <AddIcon/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoices.map(InvoiceRow)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {id && <PeopleInvoiceDialog open={invoiceDialogOpen} peopleId={id} people={people} invoiceDate={selectedDate} initLines={invoiceBaseLines()} handleClose={handleInvoiceClose} />}
                            </Fragment>)}
                        
                    </Box>
                </Slide>
            }
            <BonusDialog open={bonusOpen} handleClose={onCloseBonus} peopleId={people?.peopleId} />
        </Fragment>);
}


export default PeopleDetailPage;