import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, CardActions, CardContent, CardHeader, CircularProgress, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import { CAStat } from "../../models/Stats";
import { Period } from "../../models/Period";
import { getStartDate } from "../../functions/dateUtils";
import { ccyFormat } from "../../functions/formaters";

interface IProps {
    title: string;
    objective: number;
    ariaLabel?: string;
    stats: CAStat[];
}

const CACard = ({ ariaLabel, title, objective, stats}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const [period, setPeriod] = useState<Period>("yesterday");

    const startOfReport = getStartDate(period);
    const endOfReport = period == "yesterday" ? getStartDate("day") : new Date();
    const caStats = stats.filter(s => new Date(s.date) >= startOfReport  && new Date(s.date) < endOfReport);
    const currentCA = caStats.reduce((sum, stat) => sum + stat.amount, 0);

    const progressColor = currentCA >= objective ? "success" : "error";
    const progressValue = Math.min(100, 100 * currentCA / (2 * Math.max(1, objective)));

    const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
        if(newPeriod != null) {
            setPeriod(newPeriod as Period);
        }
    };

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                <Stack justifyContent="center" alignItems="center" justifyItems="center">
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress size={200} thickness={2} variant="determinate" value={progressValue} color={progressColor} />
                        <Box
                            sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            }}
                        >
                            <Typography
                            variant="h5"
                            component="div"
                            color="text.secondary"
                            >{`${ccyFormat(Math.round(currentCA))}`}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
            <CardActions>
                <ToggleButtonGroup
                color="primary"
                value={period}
                exclusive
                size="small"
                onChange={handlePeriodChange}
                >
                    <ToggleButton value="yesterday">{intl.formatMessage({id: "period.yesterday"})}</ToggleButton>
                    <ToggleButton value="week">{intl.formatMessage({id: "period.week"})}</ToggleButton>
                    <ToggleButton value="month">{intl.formatMessage({id: "period.month"})}</ToggleButton>
                </ToggleButtonGroup>
            </CardActions>
        </Card>
    );
}

export default CACard;
