import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import * as _ from "lodash";

import {  Box, Button, CardActions, CardContent, CardHeader, LinearProgress, Stack, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";

import { ContractCAStat } from "../../models/Stats";
import { Period } from "../../models/Period";
import { getStartDate } from "../../functions/dateUtils";
import { ccyFormat } from "../../functions/formaters";

interface IProps {
    title: string;
    ariaLabel?: string;
    stats: ContractCAStat[];
}

const ContractCard = ({ ariaLabel, stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();
    const [period, setPeriod] = useState<Period>("yesterday");

    const startOfReport = getStartDate(period);
    const endOfReport = period == "yesterday" ? getStartDate("day") : new Date();

    const reportStats = stats.filter(s => new Date(s.date) > startOfReport && new Date(s.date) < endOfReport);
    const totalCA = Math.max(1, reportStats.reduce((s, c) => s + c.amount, 0));
    const totalMargin = Math.max(1, reportStats.reduce((s, c) => s + c.margin, 0));

    const contractsStats = _.chain(reportStats)
    .groupBy(s => s.contractId)
    .map((res, c) => { return {id: c, ca: res.reduce((s, r) => r.amount + s , 0), margin: res.reduce((s, r) => r.margin + s , 0)}})
    .sortBy(c => -c.ca)
    .value();

    const handlePeriodChange = (
        event: React.MouseEvent<HTMLElement>,
        newPeriod: string,
    ) => {
        if(newPeriod != null) {
            setPeriod(newPeriod as Period);
        }
    };

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                {contractsStats.length == 0 ? <Typography>{intl.formatMessage({id: "nodata"})}</Typography> : <></>}
                <Table  size="small" aria-label="simple table">
                    <TableBody>
                    {contractsStats.map((row) => {
                        const contract = reportStats.find(c => c.contractId === row.id);
                        return (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{minWidth: 35, maxWidth: 35, paddingLeft: 0}}>
                                <Button onClick={() => navigate(`/contract/${row.id}`)} variant="text"><Typography sx={{textSizeAdjust: "auto"}}>{contract?.contractName}</Typography></Button>
                            </TableCell>
                            <TableCell sx={{minWidth: 60, maxWidth: 60, paddingLeft: 2}} align="right">
                                <Stack>
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                        <Box sx={{ width: '100%', mr: 1 }}>
                                            <LinearProgress value={row.ca * 100 / totalCA} variant="determinate" />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{ccyFormat(row.ca)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                        <Box sx={{ width: '100%', mr: 1 }}>
                                            <LinearProgress value={row.margin * 100 / totalMargin} color={row.margin > 0 ? "success" : "error"} variant="determinate" />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography sx={{textSizeAdjust: "auto"}} variant="body2" color="text.secondary"><b>{ccyFormat(row.margin)}</b></Typography>
                                        </Box>
                                    </Box>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )})}
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                <ToggleButtonGroup
                color="primary"
                value={period}
                exclusive
                size="small"
                onChange={handlePeriodChange}
                >
                    <ToggleButton value="yesterday">{intl.formatMessage({id: "period.yesterday"})}</ToggleButton>
                    <ToggleButton value="week">{intl.formatMessage({id: "period.week"})}</ToggleButton>
                    <ToggleButton value="month">{intl.formatMessage({id: "period.month"})}</ToggleButton>
                </ToggleButtonGroup>
            </CardActions>
        </Card>
    );
}

export default ContractCard;
