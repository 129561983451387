import { useState } from "react";
import { useIntl } from "react-intl";
import { Avatar, Box, CardActions, CardContent, CardHeader, CircularProgress, LinearProgress, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import { ExpenseStat } from "../../models/Stats";
import { getStartDate } from "../../functions/dateUtils";
import { Period } from "../../models/Period";
import { ccyFormat } from "../../functions/formaters";

interface IProps {
    title: string;
    stats: ExpenseStat[];
}

const ExpenseCard = ({ stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();

    const [period, setPeriod] = useState<Period>("month");

    const startOfMonth = getStartDate("month");
    const expenses = stats.filter(s => new Date(s.date) >= startOfMonth);
    expenses.sort(e => e.amount);
    const totalCosts = expenses.reduce((s, e) => e.amount + s,0);

    const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
        if(newPeriod != null) {
            setPeriod(newPeriod as Period);
        }
    };
    
    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white, pb: 0}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                <Typography variant="h4" gutterBottom>
                    {ccyFormat(totalCosts)}
                </Typography>
                <Table  size="small" aria-label="simple table">
                    <TableBody>
                    {expenses.map((row) => (
                        <TableRow
                        key={`${row.kind}-${row.date}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{minWidth: 10, maxWidth:30}}>
                                {intl.formatMessage({id: `expense.kind.${row.kind.toLocaleLowerCase()}`})}
                            </TableCell>
                            <TableCell align="right">
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: '100%', mr: 1 }}>
                                        <LinearProgress value={row.amount * 100 / totalCosts} variant="determinate" />
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" color="text.secondary">{ccyFormat(row.amount)}</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                <ToggleButtonGroup
                color="primary"
                value={period}
                exclusive
                size="small"
                onChange={handlePeriodChange}
                >
                    <ToggleButton value="month">{intl.formatMessage({id: "period.month"})}</ToggleButton>
                </ToggleButtonGroup>
            </CardActions>
        </Card>
    );
}

export default ExpenseCard;
